import React, { useEffect, useState } from "react"
import { Container, Row } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import Overview from "./Overview"
import DetailsSection from "./DetailsSection"
import { useLoading } from "helpers/custom_hooks"
import { useParams } from "react-router-dom"
import { GetData } from "helpers/backend_helper"

const CustomerDetails = () => {
  const setLoading = useLoading()
  const [entry, setEntry] = useState()
  const { id } = useParams()

  const loadData = async () => {
    setLoading(true)
    const result = await GetData("/customers/" + id)
    setEntry(result)
    setLoading(false)
  }

  useEffect(() => {
    loadData()
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Details" breadcrumbItem="Kunde Details" />

          <Row>
            <Overview entry={entry} />
            <DetailsSection entry={entry} />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default CustomerDetails
