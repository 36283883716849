import React from "react"
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api"

const libraries = ["places"]

const GoogleMapComp = ({ lng, lat }) => {
  const mapContainerStyle = {
    height: "100%",
    width: "100%",
  }
  const center = {
    lat, // default latitude
    lng, // default longitude
  }
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyCkS0l56SCELQWiWZP78DdjQ2zlb6WTIy4",
    libraries,
  })

  if (!isLoaded) {
    return null
  }

  return (
    <div style={{ height: "50vh", width: "100%" }}>
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        zoom={10}
        center={center}
      >
        <Marker position={center} />
      </GoogleMap>
    </div>
  )
}

export default GoogleMapComp
