import React, { useEffect, useState } from "react"
import { Badge, Container, Row } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import { useLoading } from "helpers/custom_hooks"
import { Link, useParams } from "react-router-dom"
import { GetData } from "helpers/backend_helper"
import { Card, CardBody, Col } from "reactstrap"
import GoogleMapComp from "./GoogleMap"
import AppointmentModal from "./AppointmentModal"
import moment from "moment"
import {
  getAppointmentTypeColor,
  IsAdminUser,
  isTimeMidnight,
} from "helpers/misc_helpers"

const AppointmentsDetails = () => {
  const setLoading = useLoading()
  const [entry, setEntry] = useState()
  const [modal, setModal] = useState(false)
  const { id } = useParams()

  const loadData = async () => {
    setLoading(true)
    const result = await GetData("/appointments/" + id)
    setEntry(result)
    setLoading(false)
  }

  useEffect(() => {
    loadData()
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Details" breadcrumbItem="Termin Details" />

          <Row>
            <Col xl={6}>
              <Card>
                <CardBody>
                  <h5 className="fw-semibold mb-3">Übersicht</h5>

                  <div className="table-responsive">
                    <table className="table">
                      <tbody>
                        <tr>
                          <th scope="col">Bvh.: </th>
                          <td scope="col">
                            <Link
                              to={
                                IsAdminUser()
                                  ? `/standorte/${entry?.location_id}`
                                  : "#"
                              }
                            >
                              {entry?.location_name}
                            </Link>
                          </td>
                        </tr>
                        <tr>
                          <th scope="col">Adresse: </th>
                          <td scope="col">
                            {entry?.address}
                            <br /> {entry?.postal_code} {entry?.city}
                          </td>
                        </tr>
                        <tr>
                          <th scope="col">Bereich: </th>
                          <td scope="col">{entry?.area_name ?? "-"}</td>
                        </tr>
                        <tr>
                          <th scope="col">Kunde: </th>
                          <td scope="col">{entry?.customer_name}</td>
                        </tr>
                        <tr>
                          <th scope="row">Telefonnummer:</th>
                          <td>
                            <a href={"tel:" + entry?.customer_phone_number}>
                              {entry?.customer_phone_number ?? "-"}
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <th scope="col">Termin: </th>
                          <td scope="col">
                            {moment
                              .utc(entry?.date)
                              .format(
                                isTimeMidnight(entry?.date)
                                  ? "DD.MM.YYYY"
                                  : "DD.MM.YYYY HH:mm"
                              )}
                          </td>
                        </tr>
                        <tr>
                          <th scope="col">WC-Anlage: </th>
                          <td scope="col">
                            {entry?.quantity} x {entry?.facility_name}
                          </td>
                        </tr>
                        <tr>
                          <th scope="col">Wartungsintervall: </th>
                          <td scope="col">
                            {entry?.maintenance_interval} Tage
                          </td>
                        </tr>
                        <tr>
                          <th scope="col">Letzte Wartung: </th>
                          <td scope="col">
                            {entry?.last_appointment_date
                              ? moment
                                  .utc(entry?.last_appointment_date)
                                  .format("DD.MM.YYYY")
                              : "-"}
                          </td>
                        </tr>
                        <tr>
                          <th scope="col">Nächste Wartung: </th>
                          <td scope="col">
                            {entry?.next_appointment_date
                              ? moment
                                  .utc(entry?.next_appointment_date)
                                  .format("DD.MM.YYYY")
                              : "-"}
                          </td>
                        </tr>
                        <tr>
                          <th scope="col">Typ: </th>
                          <td scope="col">
                            <Badge
                              className="py-1 px-2"
                              color={getAppointmentTypeColor(entry?.type)}
                            >
                              {entry?.type}
                            </Badge>
                          </td>
                        </tr>
                        <tr>
                          <th scope="col">Status: </th>
                          <td scope="col">
                            {entry?.completed == 1 && (
                              <span className="badge bg-success py-1 px-2">
                                erledigt
                              </span>
                            )}

                            {entry?.completed == 0 && (
                              <span className="badge bg-warning py-1 px-2">
                                offen
                              </span>
                            )}

                            {entry?.completed == -1 && (
                              <span className="badge bg-danger py-1 px-2">
                                Nicht Erfolgreich
                              </span>
                            )}
                          </td>
                        </tr>
                        <tr>
                          <th scope="col">Notiz: </th>
                          <td scope="col">{entry?.note}</td>
                        </tr>
                        {entry?.completed != 0 && (
                          <>
                            <tr>
                              <th scope="col">Abgeschlossen am: </th>
                              <td scope="col">
                                {entry?.completed_at
                                  ? moment
                                      .utc(entry?.completed_at)
                                      .format("DD.MM.YYYY HH:mm")
                                  : "-"}
                              </td>
                            </tr>
                            <tr>
                              <th scope="col">Notiz Fahrer: </th>
                              <td scope="col">{entry?.completion_note}</td>
                            </tr>
                            {entry?.signature_url && (
                              <tr>
                                <th scope="col">Unterschrift: </th>
                                <td scope="col">
                                  <img src={entry?.signature_url} />
                                </td>
                              </tr>
                            )}
                          </>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div className="hstack gap-2">
                    <button
                      className="btn btn-soft-dark w-100"
                      disabled={!entry?.latitude || !entry?.longitude}
                      onClick={_ =>
                        window.open(
                          `https://maps.google.com/?q=${entry?.latitude},${entry?.longitude}`,
                          "_blank",
                          "noreferrer"
                        )
                      }
                    >
                      Route öffnen
                    </button>
                    <button
                      className="btn btn-primary w-100"
                      onClick={_ => setModal(true)}
                      disabled={entry?.completed == 1}
                    >
                      Erledigt (Ja / Nein)
                    </button>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col>
              <Card>
                <CardBody>
                  {(!entry?.latitude || !entry?.longitude) && (
                    <h6 className="text-center mt-3">
                      Addressinformation nicht vorhanden!
                    </h6>
                  )}
                  {entry?.latitude && entry?.longitude && (
                    <GoogleMapComp
                      lat={entry?.latitude}
                      lng={entry?.longitude}
                    />
                  )}
                </CardBody>
              </Card>
            </Col>
            <AppointmentModal
              modal={modal}
              toggle={_ => setModal(false)}
              appointment={entry}
            />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AppointmentsDetails
