import { CreateEntry, UpdateEntry } from "helpers/backend_helper"
import { useLoading } from "helpers/custom_hooks"
import React, { useEffect, useState } from "react"
import {
  Col,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap"

const UserModal = ({ modal, toggle, selectedEntry }) => {
  const setLoading = useLoading()
  const [reqObj, setReqObj] = useState({})

  const handleSave = async () => {
    try {
      setLoading(true)

      if (selectedEntry) await UpdateEntry("/users/" + reqObj.id, reqObj)
      else await CreateEntry("/users/", reqObj)

      toggle()
    } catch (error) {
      setLoading(false)
      if (error?.response?.status == 400) alert(error.response.data)
      else alert("Es ist ein unerwarteter Fehler aufgetreten!")
    }
  }

  useEffect(() => {
    if (!selectedEntry) setReqObj({ is_admin: 0 })
    else setReqObj(selectedEntry)
  }, [selectedEntry, modal])

  return (
    <Modal isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle} tag="h4">
        {selectedEntry ? "User bearbeiten" : "Neuer User"}
      </ModalHeader>
      <ModalBody>
        <Form
          onSubmit={e => {
            e.preventDefault()
            handleSave()
          }}
        >
          <Row>
            <Col className="col-12">
              <div className="mb-3">
                <Label className="form-label">Name</Label>
                <Input
                  type="text"
                  value={reqObj?.name ?? ""}
                  onChange={e => setReqObj({ ...reqObj, name: e.target.value })}
                />
              </div>
              <div className="mb-3">
                <Label className="form-label">Username</Label>
                <Input
                  type="text"
                  value={reqObj?.username ?? ""}
                  onChange={e =>
                    setReqObj({ ...reqObj, username: e.target.value })
                  }
                />
              </div>
              <div className="mb-3">
                <Label className="form-label">Passwort</Label>
                <Input
                  type="password"
                  onChange={e =>
                    setReqObj({ ...reqObj, password: e.target.value })
                  }
                />
              </div>
              <div className="mb-3">
                <label>Admin Ja / Nein:</label>
                <input
                  className="ms-2"
                  type="checkbox"
                  checked={reqObj?.is_admin == 1}
                  onChange={e =>
                    setReqObj({ ...reqObj, is_admin: e.target.checked ? 1 : 0 })
                  }
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="text-end">
                <button type="submit" className="btn btn-success save-user">
                  Speichern
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  )
}

export default UserModal
