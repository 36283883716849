import { CreateEntry, UpdateEntry } from "helpers/backend_helper"
import { useLoading } from "helpers/custom_hooks"
import React, { useEffect, useState } from "react"
import {
  Col,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap"

const CustomerModal = ({ modal, toggle, selectedEntry }) => {
  const setLoading = useLoading()
  const [reqObj, setReqObj] = useState({})

  const handleSave = async () => {
    setLoading(true)

    if (selectedEntry) await UpdateEntry("/customers/" + reqObj.id, reqObj)
    else await CreateEntry("/customers/", reqObj)

    toggle()
  }

  useEffect(() => {
    if (!selectedEntry) setReqObj({})
    else setReqObj(selectedEntry)
  }, [selectedEntry, modal])

  return (
    <Modal isOpen={modal} toggle={toggle} size="xl">
      <ModalHeader toggle={toggle} tag="h4">
        {selectedEntry ? "Kunde bearbeiten" : "Neuer Kunde"}
      </ModalHeader>
      <ModalBody>
        <Form
          onSubmit={e => {
            e.preventDefault()
            handleSave()
          }}
        >
          <Row>
            <Col className="col-12">
              <Row>
                <Col className="col-6">
                  <div className="mb-3">
                    <Label className="form-label">Vorname</Label>
                    <Input
                      type="text"
                      value={reqObj?.first_name ?? ""}
                      onChange={e =>
                        setReqObj({ ...reqObj, first_name: e.target.value })
                      }
                    />
                  </div>
                </Col>
                <Col className="col-6">
                  <div className="mb-3">
                    <Label className="form-label">Nachname</Label>
                    <Input
                      type="text"
                      value={reqObj?.last_name ?? ""}
                      onChange={e =>
                        setReqObj({ ...reqObj, last_name: e.target.value })
                      }
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col className="col-6">
                  <div className="mb-3">
                    <Label className="form-label">Firma (optional)</Label>
                    <Input
                      type="text"
                      value={reqObj?.company_name ?? ""}
                      onChange={e =>
                        setReqObj({ ...reqObj, company_name: e.target.value })
                      }
                    />
                  </div>
                </Col>
                <Col className="col-6">
                  <div className="mb-3">
                    <Label className="form-label">Adresse</Label>
                    <Input
                      type="text"
                      value={reqObj?.address ?? ""}
                      onChange={e =>
                        setReqObj({ ...reqObj, address: e.target.value })
                      }
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col className="col-6">
                  <div className="mb-3">
                    <Label className="form-label">PLZ</Label>
                    <Input
                      type="text"
                      value={reqObj?.postal_code ?? ""}
                      onChange={e =>
                        setReqObj({ ...reqObj, postal_code: e.target.value })
                      }
                    />
                  </div>
                </Col>
                <Col className="col-6">
                  <div className="mb-3">
                    <Label className="form-label">Ort</Label>
                    <Input
                      type="text"
                      value={reqObj?.city ?? ""}
                      onChange={e =>
                        setReqObj({ ...reqObj, city: e.target.value })
                      }
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col className="col-6">
                  <div className="mb-3">
                    <Label className="form-label">UID-Nummer (optional)</Label>
                    <Input
                      type="text"
                      value={reqObj?.vat_number ?? ""}
                      onChange={e =>
                        setReqObj({ ...reqObj, vat_number: e.target.value })
                      }
                    />
                  </div>
                </Col>
                <Col className="col-6">
                  <div className="mb-3">
                    <Label className="form-label">
                      Telefonnummer (optional)
                    </Label>
                    <Input
                      type="text"
                      value={reqObj?.phone_number ?? ""}
                      onChange={e =>
                        setReqObj({ ...reqObj, phone_number: e.target.value })
                      }
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col className="col-6">
                  <div className="mb-3">
                    <Label className="form-label">E-Mail (optional)</Label>
                    <Input
                      type="text"
                      value={reqObj?.email ?? ""}
                      onChange={e =>
                        setReqObj({ ...reqObj, email: e.target.value })
                      }
                    />
                  </div>
                </Col>
                <Col className="col-6"></Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="text-end">
                <button type="submit" className="btn btn-success save-user">
                  Speichern
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  )
}

export default CustomerModal
