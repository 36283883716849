import React from "react"
import { Link } from "react-router-dom"
import moment from "moment"

export const columns = [
  {
    dataField: "id",
    text: "Nummer",
    sort: true,
  },
  {
    dataField: "name",
    text: "Name",
    sort: true,
    formatter: (_, element) => (
      <Link to={"/standorte/" + element.id}>{element.name}</Link>
    ),
  },
  {
    dataField: "customer_name",
    text: "Kunde",
    sort: true,
    formatter: (_, element) => (
      <Link to={"/kunden/" + element.customer_id}>{element.customer_name}</Link>
    ),
  },
  {
    dataField: "address",
    text: "Adresse",
    sort: true,
  },
  {
    dataField: "postal_code",
    text: "PLZ",
    sort: true,
  },
  {
    dataField: "city",
    text: "Ort",
    sort: true,
  },
  {
    dataField: "delivery_date",
    text: "Lieferdatum",
    sort: true,
    headerStyle: _ => ({
      textAlign: "center",
    }),
    align: () => "center",
    formatter: (_, element) => (
      <div>
        {element?.delivery_date
          ? moment(element?.delivery_date).format("DD.MM.YYYY")
          : "-"}
      </div>
    ),
  },
  {
    dataField: "pickup_date",
    text: "Abholdatum",
    sort: true,
    headerStyle: _ => ({
      textAlign: "center",
    }),
    align: () => "center",
    formatter: (_, element) => (
      <div>
        {element?.pickup_date
          ? moment(element?.pickup_date).format("DD.MM.YYYY")
          : "-"}
      </div>
    ),
  },
  {
    dataField: "menu",
    isDummyField: true,
    editable: false,
    text: "",
    align: () => "end",
    formatter: (_, element) => (
      <div className="d-flex justify-content-end gap-3">
        <Link className="btn btn-primary" to={"/standorte/" + element.id}>
          Details
        </Link>
      </div>
    ),
  },
]
