import {
  CreateEntry,
  GetUsersByName,
  UpdateEntry,
} from "helpers/backend_helper"
import { useLoading } from "helpers/custom_hooks"
import moment from "moment"
import React, { useEffect, useState } from "react"
import Select from "react-select"
import AsyncSelect from "react-select/async"
import {
  Col,
  Form,
  Input,
  InputGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap"
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { isTimeMidnight } from "helpers/misc_helpers"

const AppointmentModal = ({
  modal,
  onSuccess,
  onClose,
  location,
  selectedEntry,
}) => {
  const setLoading = useLoading()
  const [reqObj, setReqObj] = useState({})

  const handleSave = async () => {
    setLoading(true)
    if (selectedEntry) await UpdateEntry("/appointments/", reqObj)
    else await CreateEntry("/appointments/", reqObj)

    onSuccess()
  }

  useEffect(() => {
    const today = new Date()
    today.setHours(0, 0, 0, 0)

    if (!selectedEntry)
      setReqObj({
        location_id: location?.id,
        type: "Reinigung",
        date: today,
      })
    else setReqObj(selectedEntry)
  }, [selectedEntry, location])

  const loadUsers = async inputValue => {
    const results = await GetUsersByName(inputValue)

    return results.map(entry => ({
      value: entry.id,
      label: entry.name,
    }))
  }

  const isTimeValid = time => {
    // Überprüft das Format HH:mm
    const regex = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/
    return regex.test(time)
  }

  const handleTimeChange = event => {
    const timeInput = event.target.value

    if (isTimeValid(timeInput)) {
      const date = moment(reqObj.date).format("YYYY-MM-DD") // Extrahiere das Datum aus dem vorhandenen Wert
      const newDateTime = `${date} ${timeInput}` // Kombiniere das Datum mit der neuen Uhrzeit
      setReqObj({ ...reqObj, date: moment(newDateTime) })
    } else {
      if (timeInput)
        return alert(
          "Bitte geben Sie eine gültige Uhrzeit im Format HH:mm ein."
        )

      const startOfDay = moment(reqObj.date).startOf("day") // Start des Tages in ISO-Format
      setReqObj({ ...reqObj, date: startOfDay })
    }
  }

  return (
    <Modal isOpen={modal} toggle={onClose} centered>
      <ModalHeader toggle={onClose} tag="h4">
        {selectedEntry ? "Termin bearbeiten" : "Termin hinzufügen"}
      </ModalHeader>
      <ModalBody>
        <Form
          onSubmit={e => {
            e.preventDefault()
            handleSave()
          }}
        >
          <Row>
            <Col className="col-12">
              <div className="mb-3">
                <Label className="form-label">Anlage:</Label>
                <Select
                  className="select2-selection w-100"
                  defaultValue={{
                    value: selectedEntry?.booking_id,
                    label: selectedEntry?.facility_name,
                  }}
                  isDisabled={selectedEntry}
                  onChange={selectedOption => {
                    setReqObj({
                      ...reqObj,
                      booking_id: selectedOption.value,
                      facility_id: location?.bookings?.find(
                        b => b.id == selectedOption.value
                      )?.facility_id,
                    })
                  }}
                  options={location?.bookings?.map(b => ({
                    label: b?.facility_name,
                    value: b.id,
                  }))}
                />
              </div>

              <div className="mb-3">
                <Label className="form-label">Datum Termin:</Label>

                <input
                  type="date"
                  className="form-control"
                  value={moment(reqObj?.date).format("YYYY-MM-DD")}
                  disabled={reqObj.type != "Reinigung"}
                  onChange={e =>
                    setReqObj({
                      ...reqObj,
                      date: e.target.value,
                    })
                  }
                />
              </div>
              <div className="mb-3">
                <Label className="form-label">Uhrzeit (optional):</Label>

                <Input
                  type="text"
                  defaultValue={
                    !reqObj?.date || isTimeMidnight(reqObj?.date)
                      ? ""
                      : moment(reqObj?.date).format("HH:mm")
                  }
                  onBlur={handleTimeChange}
                />
              </div>

              <div className="mb-3">
                <Label className="form-label">Mitarbeiter:</Label>
                <AsyncSelect
                  loadOptions={loadUsers}
                  className="select2-selection w-100"
                  defaultValue={{
                    value: selectedEntry?.user_id,
                    label: selectedEntry?.user_name,
                  }}
                  onChange={selectedOption => {
                    setReqObj({
                      ...reqObj,
                      user_id: selectedOption.value,
                    })
                  }}
                />
              </div>

              <div className="mb-3">
                <Label className="form-label">Preis : </Label>
                <Input
                  type="text"
                  value={reqObj?.flatrate ?? "0"}
                  onChange={e =>
                    setReqObj({ ...reqObj, flatrate: e.target.value })
                  }
                />
              </div>

              <div className="mb-3">
                <Label className="form-label">Notiz:</Label>
                <Input
                  type="text"
                  value={reqObj?.note ?? ""}
                  onChange={e => setReqObj({ ...reqObj, note: e.target.value })}
                />
              </div>

              <div className="mb">
                <Input
                  id="checkboxImportant"
                  type="checkbox"
                  checked={reqObj?.important == 1}
                  onChange={e =>
                    setReqObj({
                      ...reqObj,
                      important: e.target.checked ? 1 : 0,
                    })
                  }
                />
                <Label
                  className="form-label ms-2"
                  for="checkboxImportant"
                  style={{ cursor: "pointer" }}
                >
                  Kritischer Termin (Ja / Nein)
                </Label>
              </div>

              <div className="mb-1">
                <Input
                  id="checkboxCompleted"
                  type="checkbox"
                  checked={reqObj?.completed == 1}
                  onChange={e =>
                    setReqObj({
                      ...reqObj,
                      completed: e.target.checked ? 1 : 0,
                    })
                  }
                />
                <Label
                  className="form-label ms-2"
                  for="checkboxCompleted"
                  style={{ cursor: "pointer" }}
                >
                  Abgeschlossen (Ja / Nein)
                </Label>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="text-end">
                <button type="submit" className="btn btn-success save-user">
                  Speichern
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  )
}

export default AppointmentModal
