import UserInfoModal from "components/Common/UserInfoModal"
import { UpdateEntry } from "helpers/backend_helper"
import { useLoading } from "helpers/custom_hooks"
import moment from "moment"
import React, { useEffect, useState } from "react"
import {
  Col,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap"

const BookingPauseModal = ({ modal, onSuccess, onClose, booking }) => {
  const setLoading = useLoading()
  const [reqObj, setReqObj] = useState({})
  const [error, setError] = useState()

  const handleSave = async () => {
    setLoading(true)

    const errorMsg = checkReqObj(reqObj)
    if (errorMsg) {
      setLoading(false)
      return setError(errorMsg)
    }

    await UpdateEntry("/bookings/updatePausePeriod/" + reqObj.id, reqObj)

    onSuccess()
  }

  const checkReqObj = () => {
    if (
      (reqObj.pause_start_date && !reqObj.pause_end_date) ||
      (!reqObj.pause_start_date && reqObj.pause_end_date)
    )
      return "Bitte alle Pflichtfelder befüllen!"

    const pause_start_date = moment(reqObj.pause_start_date)
    const pause_end_date = moment(reqObj.pause_end_date)

    if (pause_start_date.isAfter(pause_end_date)) {
      return "Das Enddatum darf nicht vor dem Startdatum liegen!"
    }

    return null
  }

  useEffect(() => {
    setReqObj(booking)
  }, [booking])

  return (
    <Modal isOpen={modal} toggle={onClose} centered>
      <ModalHeader toggle={onClose} tag="h4">
        Buchung Stillegung
      </ModalHeader>
      <ModalBody>
        <Form
          onSubmit={e => {
            e.preventDefault()
            handleSave()
          }}
        >
          <Row>
            <Col className="col-12">
              <div className="mb-3">
                <Label className="form-label">Stillegung Start:</Label>

                <input
                  type="date"
                  className="form-control"
                  value={
                    reqObj?.pause_start_date
                      ? moment(reqObj.pause_start_date).format("YYYY-MM-DD")
                      : ""
                  }
                  onChange={e =>
                    setReqObj({
                      ...reqObj,
                      pause_start_date: moment(e.target.value),
                    })
                  }
                />
              </div>
              <div className="mb-3">
                <Label className="form-label">Stillegung Ende:</Label>

                <Input
                  type="date"
                  className="form-control"
                  value={
                    reqObj?.pause_end_date
                      ? moment(reqObj.pause_end_date).format("YYYY-MM-DD")
                      : ""
                  }
                  onChange={e =>
                    setReqObj({
                      ...reqObj,
                      pause_end_date: moment(e.target.value),
                    })
                  }
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="text-end">
                <button type="submit" className="btn btn-success save-user">
                  Speichern
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </ModalBody>
      <UserInfoModal
        show={error != undefined}
        message={error}
        onConfirm={_ => setError()}
      />
    </Modal>
  )
}

export default BookingPauseModal
