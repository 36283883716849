import React, { useEffect, useState } from "react"

import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator"
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import "flatpickr/dist/themes/material_blue.css"
const { SearchBar } = Search
import { Link } from "react-router-dom"
import DeleteModal from "../../components/Common/DeleteModal"
import { Col, Row, Card, CardBody, Table } from "reactstrap"
import { DeleteEntry, GetData } from "helpers/backend_helper"
import { useLoading } from "helpers/custom_hooks"
import CustomerModal from "./CustomerModal"

function Customers() {
  const setLoading = useLoading()
  const [data, setData] = useState([])
  const [entryModal, setEntryModal] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [selectedEntry, setSelectedEntry] = useState(false)
  const pageOptions = {
    sizePerPage: 20,
    totalSize: data.length,
    custom: true,
  }

  const handleDelete = async () => {
    setLoading(true)
    await DeleteEntry("/customers/" + selectedEntry.id)
    setDeleteModal(false)
    loadData()
  }

  const loadData = async () => {
    setLoading(true)
    const data = await GetData("/customers")
    setData(data)
    setLoading(false)
  }

  useEffect(() => {
    loadData()
  }, [])

  const columns = [
    {
      dataField: "company_name",
      text: "Name",
      sort: true,
      formatter: (_, element) => (
        <Link to={"/kunden/" + element.id}>
          {element.company_name ?? element.first_name + " " + element.last_name}
        </Link>
      ),
    },
    {
      dataField: "address",
      text: "Adresse",
      sort: true,
    },
    {
      dataField: "postal_code",
      text: "PLZ",
      sort: true,
    },
    {
      dataField: "city",
      text: "Ort",
      sort: true,
    },
    {
      dataField: "phone_number",
      text: "Telefonnummer",
      sort: true,
    },
    {
      dataField: "email",
      text: "E-Mail",
      sort: true,
    },
    {
      dataField: "menu",
      isDummyField: true,
      editable: false,
      text: "",
      align: () => "end",
      formatter: (_, element) => (
        <div className="d-flex justify-content-end gap-3">
          <Link className="text-success" to="#">
            <i
              className="mdi mdi-pencil font-size-18"
              onClick={() => {
                setSelectedEntry(element)
                setEntryModal(true)
              }}
            ></i>
          </Link>
          <Link className="text-danger" to="#">
            <i
              className="mdi mdi-delete font-size-18"
              onClick={() => {
                setSelectedEntry(element)
                setDeleteModal(true)
              }}
            ></i>
          </Link>
        </div>
      ),
    },
  ]

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions || [])}
                    keyField="id"
                    columns={columns || []}
                    data={data || []}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        data={data}
                        columns={columns || []}
                        remote={{ filter: true }}
                        bootstrap4
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row>
                              <Col>
                                <h5 className="mb-0 card-title flex-grow-1">
                                  Kunden
                                </h5>
                              </Col>
                              <Col>
                                <div className="d-flex justify-content-end align-items-center">
                                  <SearchBar
                                    placeholder="Suche..."
                                    style={{ width: "250px" }}
                                    {...toolkitProps.searchProps}
                                  />
                                  <div className="flex-shrink-0 mb-2 ms-3">
                                    <button
                                      className="btn btn-primary"
                                      onClick={_ => {
                                        setSelectedEntry()
                                        setEntryModal(true)
                                      }}
                                    >
                                      Neuer Kunde
                                    </button>
                                    <Link
                                      to="#!"
                                      className="btn btn-light ms-3"
                                    >
                                      <i className="mdi mdi-refresh"></i>
                                    </Link>
                                  </div>
                                </div>
                              </Col>
                            </Row>

                            <div className="table-responsive">
                              <BootstrapTable
                                {...toolkitProps.baseProps}
                                {...paginationTableProps}
                                bordered={false}
                                hover={true}
                                classes={
                                  "table align-middle table-nowrap table-check"
                                }
                                headerWrapperClasses={"table-light"}
                              />
                            </div>
                            <div className="pagination pagination-rounded justify-content-end mb-2">
                              <PaginationListStandalone {...paginationProps} />
                            </div>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      <CustomerModal
        modal={entryModal}
        toggle={_ => {
          setEntryModal(false)
          loadData()
        }}
        selectedEntry={selectedEntry}
      />
      <DeleteModal
        onCloseClick={_ => setDeleteModal(false)}
        onDeleteClick={handleDelete}
        show={deleteModal}
      />
    </React.Fragment>
  )
}

export default Customers
