import React from "react"
import { Modal, ModalBody } from "reactstrap"

const UserInfoModal = ({ show, onConfirm, message }) => {
  return (
    <Modal size="sm" isOpen={show} centered={true}>
      <div className="modal-content">
        <ModalBody className="px-4 pt-5 text-center">
          <p className="text-muted font-size-16 mb-4">{message}</p>

          <div className="hstack gap-2 justify-content-center mb-0">
            <button
              type="button"
              className="btn btn-primary w-100"
              onClick={onConfirm}
            >
              Okay
            </button>
          </div>
        </ModalBody>
      </div>
    </Modal>
  )
}

export default UserInfoModal
