import React from "react"
import { Navigate } from "react-router-dom"
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Standorte from "pages/Locations"
import LocationDetails from "pages/LocationDetails"
import NewLocation from "pages/NewLocation"
import Customers from "pages/Customers"
import CustomerDetails from "pages/CustomerDetails"
import Userlist from "pages/users"
import Appointments from "pages/Appointments"
import Calender from "pages/Calendar"
import Einstellungen from "pages/Einstellungen"
import Veranstaltungen from "pages/Veranstaltungen"
import Page404 from "pages/Util/page-404"
import Register from "pages/Authentication/Register"
import Verrechnung from "pages/Verrechnung"
import Facilities from "pages/Facilities"
import Areas from "pages/Areas"
import AppointmentsDetails from "pages/AppointmentDetails"

const authProtectedRoutes = [
  { path: "/einstellungen", component: <Einstellungen /> },
  { path: "/standorte", component: <Standorte /> },
  { path: "/standorte/:id", component: <LocationDetails /> },
  { path: "/neuer-standort", component: <NewLocation /> },
  { path: "/kunden", component: <Customers /> },
  { path: "/kunden/:id", component: <CustomerDetails /> },
  { path: "/users", component: <Userlist /> },
  { path: "/termine", component: <Appointments /> },
  { path: "/termine/:id", component: <AppointmentsDetails /> },
  { path: "/kalender", component: <Calender /> },
  { path: "/bereiche", component: <Areas /> },
  { path: "/anlagen", component: <Facilities /> },
  { path: "/veranstaltungen", component: <Veranstaltungen /> },
  { path: "/verrechnung", component: <Verrechnung /> },
  {
    path: "/",
    exact: true,
    component: <Navigate to="/termine" />,
  },
]

const publicRoutes = [
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/register", component: <Register /> },
  { path: "*", component: <Page404 /> },
]

export { authProtectedRoutes, publicRoutes }
