import React from "react"
import { Container } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"

const Einstellungen = props => {
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Einstellungen" breadcrumbItem="Einstellungen" />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Einstellungen
