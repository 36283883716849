import React from "react"
import { Link } from "react-router-dom"
import { Card, CardBody, Col } from "reactstrap"

const Overview = ({ entry }) => {
  return (
    <React.Fragment>
      <Col xl={4}>
        <Card>
          <CardBody>
            <h5 className="fw-semibold">Übersicht</h5>

            <div className="table-responsive">
              <table className="table">
                <tbody>
                  <tr>
                    <th scope="col">Firma: </th>
                    <td scope="col">
                      {entry?.company_name ? entry?.company_name : "-"}
                    </td>
                  </tr>
                  <tr>
                    <th scope="col">UID-Nummer: </th>
                    <td scope="col">
                      {entry?.vat_number ? entry?.vat_number : "-"}
                    </td>
                  </tr>
                  <tr>
                    <th scope="col">Ansprechpartner: </th>
                    <td scope="col">
                      {entry?.first_name} {entry?.last_name}
                    </td>
                  </tr>
                  <tr>
                    <th scope="col">Adresse: </th>
                    <td scope="col">
                      {entry?.address}
                      <br /> {entry?.postal_code} {entry?.city}
                    </td>
                  </tr>
                  {/* <tr>
                    <th scope="row">Anzahl Standorte:</th>
                    <td>- Stk.</td>
                  </tr>
                  <tr>
                    <th scope="row">Anzahl WCs:</th>
                    <td>- Stk.</td>
                  </tr> */}
                  <tr>
                    <th scope="row">E-Mail:</th>
                    <td>
                      <a href={"mail:" + entry?.email}>{entry?.email}</a>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Telefonnummer:</th>
                    <td>
                      <a href={"tel:" + entry?.phone_number}>
                        {entry?.phone_number}
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="hstack gap-2">
              <Link to="/neuer-standort" className="btn btn-soft-primary w-100">
                Neuer Standort
              </Link>
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  )
}

export default Overview
