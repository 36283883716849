import React, { useEffect, useState } from "react"
import DeleteModal from "../../components/Common/DeleteModal"
import { Col, Row, Card, CardBody, Table } from "reactstrap"
import AreaModal from "./AreaModal"
import { useLoading } from "helpers/custom_hooks"
import { DeleteEntry, GetData } from "helpers/backend_helper"
import { Link } from "react-router-dom"

function Areas() {
  const [data, setData] = useState([])
  const [deleteModal, setDeleteModal] = useState(false)
  const [entryModal, setEntryModal] = useState(false)
  const [selectedEntry, setSelectedEntry] = useState()
  const setLoading = useLoading()

  const handleDelete = async () => {
    setLoading(true)
    await DeleteEntry("/areas/" + selectedEntry.id)
    setDeleteModal(false)
    setSelectedEntry()
    loadData()
  }

  const loadData = async () => {
    setLoading(true)
    const data = await GetData("/areas")
    setData(data)
    setLoading(false)
  }

  useEffect(() => {
    loadData()
  }, [])

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDelete}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col lg="6">
              <Card>
                <CardBody className="border-bottom">
                  <div className="d-flex align-items-center">
                    <h5 className="mb-0 card-title flex-grow-1">Bereiche</h5>
                    <div className="flex-shrink-0">
                      <button
                        className="btn btn-primary me-1"
                        onClick={_ => {
                          setSelectedEntry()
                          setEntryModal(true)
                        }}
                      >
                        Neuer Bereich
                      </button>
                    </div>
                  </div>
                </CardBody>
                <CardBody>
                  <Table>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Beschreibung</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((element, index) => (
                        <tr key={index}>
                          <th>{element.name}</th>
                          <td>{element.description}</td>
                          <td>
                            <div className="d-flex justify-content-end gap-3">
                              <Link className="text-success" to="#">
                                <i
                                  className="mdi mdi-pencil font-size-18"
                                  id="edittooltip"
                                  onClick={() => {
                                    setSelectedEntry(element)
                                    setEntryModal(true)
                                  }}
                                ></i>
                              </Link>
                              <Link className="text-danger" to="#">
                                <i
                                  className="mdi mdi-delete font-size-18"
                                  id="deletetooltip"
                                  onClick={() => {
                                    setSelectedEntry(element)
                                    setDeleteModal(true)
                                  }}
                                ></i>
                              </Link>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      <AreaModal
        modal={entryModal}
        toggle={_ => {
          setEntryModal(false)
          loadData()
        }}
        selectedEntry={selectedEntry}
      />
      <DeleteModal
        onCloseClick={_ => setDeleteModal(false)}
        onDeleteClick={handleDelete}
        show={deleteModal}
      />
    </React.Fragment>
  )
}

export default Areas
