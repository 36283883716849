import { CreateEntry, UpdateEntry } from "helpers/backend_helper"
import { useLoading } from "helpers/custom_hooks"
import React, { useEffect, useState } from "react"
import {
  Col,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap"

const AreaModal = ({ modal, toggle, selectedEntry }) => {
  const setLoading = useLoading()
  const [reqObj, setReqObj] = useState({})

  const handleSave = async () => {
    setLoading(true)

    if (selectedEntry) await UpdateEntry("/areas/" + reqObj.id, reqObj)
    else await CreateEntry("/areas/", reqObj)

    toggle()
  }

  useEffect(() => {
    if (!selectedEntry) setReqObj({})
    else setReqObj(selectedEntry)
  }, [selectedEntry, modal])

  return (
    <Modal isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle} tag="h4">
        {selectedEntry ? "Bereich bearbeiten" : "Neuer Bereich"}
      </ModalHeader>
      <ModalBody>
        <Form
          onSubmit={e => {
            e.preventDefault()
            handleSave()
          }}
        >
          <Row>
            <Col className="col-12">
              <div className="mb-3">
                <Label className="form-label">Name</Label>
                <Input
                  type="text"
                  value={reqObj?.name ?? ""}
                  onChange={e => setReqObj({ ...reqObj, name: e.target.value })}
                />
              </div>

              <div className="mb-3">
                <Label className="form-label">Beschreibung</Label>
                <Input
                  type="text"
                  value={reqObj?.description ?? ""}
                  onChange={e =>
                    setReqObj({ ...reqObj, description: e.target.value })
                  }
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="text-end">
                <button type="submit" className="btn btn-success save-user">
                  Speichern
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  )
}

export default AreaModal
