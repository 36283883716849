import React, { useState } from "react"
import { Link } from "react-router-dom"
import DeleteModal from "../../components/Common/DeleteModal"

import {
  Col,
  Row,
  Card,
  CardBody,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
} from "reactstrap"

function Verrechnung() {
  const [job, setJob] = useState(null)

  const [deleteModal, setDeleteModal] = useState(false)
  const [modal, setModal] = useState(false)

  const handleDeletejob = () => {
    if (job && job.id) {
      setDeleteModal(false)
    }
  }

  const data = [
    {
      kunde: "JUST4ONLINE - Marcel Judth",
      adresse: "Kärntner Straße 7",
      ort: "9220 Velden am Wörthersee",
      anzahlStandorte: 1,
      anzahlWCs: 2,
    },
  ]

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeletejob}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col lg="12">
              <Card>
                <CardBody className="border-bottom">
                  <div className="d-flex align-items-center">
                    <h5 className="mb-0 card-title flex-grow-1">Standorte</h5>
                    <div className="flex-shrink-0">
                      <Link
                        to="/neuer-standort"
                        className="btn btn-primary me-1"
                      >
                        Neuer Standort
                      </Link>
                      <Link to="#!" className="btn btn-light me-1 ms-3">
                        <i className="mdi mdi-refresh"></i>
                      </Link>
                    </div>
                  </div>
                </CardBody>
                <CardBody>
                  <Table>
                    <thead>
                      <tr>
                        <th>Kunde</th>
                        <th>Adresse</th>
                        <th>Ort</th>
                        <th>Anzahl Standorte</th>
                        <th>Anzahl WCs</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((element, index) => (
                        <tr key={index}>
                          <th>
                            <Link to="/kunden/1">{element.kunde}</Link>
                          </th>
                          <td>{element.adresse}</td>
                          <td>{element.ort}</td>
                          <td>{element.anzahlStandorte}</td>
                          <td>{element.anzahlWCs}</td>
                          <td>
                            <button
                              className="btn btn-success"
                              onClick={_ => setModal(true)}
                            >
                              Verrechnet
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      <Modal isOpen={modal} toggle={_ => setModal(false)}>
        <ModalHeader toggle={_ => setModal(false)} tag="h4"></ModalHeader>
        <ModalBody>
          <div className="w-100 h-50">
            <h4>Verrechnung</h4>

            <Label className="form-label mt-4 ">Verrechnet am: </Label>
            <input
              type="date"
              className="form-control"
              defaultValue={"2023-10-03"}
            />
          </div>
          <button
            className="btn btn-success d-block ms-auto mt-2"
            onClick={_ => setModal(false)}
          >
            Speichern
          </button>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default Verrechnung
