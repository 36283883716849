import React, { useState } from "react"
import { Link } from "react-router-dom"
import { Col, Row, Table } from "reactstrap"
import BookingModal from "./BookingModal"
import { useLoading } from "helpers/custom_hooks"
import moment from "moment"
import UserConfirmationModal from "components/Common/UserConfirmationModal"
import { DeleteEntry } from "helpers/backend_helper"
import BookingPauseModal from "./BookingPauseModal"

const Bookings = ({ entry, loadData }) => {
  const [entryModal, setEntryModal] = useState(false)
  const [pauseModal, setPauseModal] = useState(false)
  const [deleteModdal, setDeleteModal] = useState(false)
  const [selectedEntry, setSelectedEntry] = useState()
  const setLoading = useLoading()

  const handleDelete = async () => {
    setLoading(true)
    await DeleteEntry("/bookings/" + selectedEntry.id)
    setDeleteModal(false)
    loadData()
  }

  return (
    <div>
      <Row className="mt-3">
        <Col>
          <h4 className="card-title mb-2">WC-Anlagen</h4>
        </Col>
        <Col className="text-end">
          <button
            className="btn btn-primary btn-sm"
            onClick={_ => {
              setSelectedEntry()
              setEntryModal(true)
            }}
          >
            hinzufügen
          </button>
        </Col>
      </Row>

      <Table>
        <thead>
          <tr>
            <th>Name</th>
            <th className="text-center">Menge</th>
            <th className="text-center">Zeitraum</th>
            <th className="text-center">Abmeldedatum</th>
            <th className="text-center">Wartung</th>
            <th>Notiz</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {entry?.bookings?.map((element, index) => (
            <tr key={index}>
              <th>{element.facility_name}</th>
              <td className="text-center">{element.quantity} Stk.</td>
              <td className="text-center">
                {element?.delivery_date
                  ? moment(element?.delivery_date).format("DD.MM.YYYY")
                  : "-"}{" "}
                -{" "}
                {element?.pickup_date
                  ? moment(element?.pickup_date).format("DD.MM.YYYY")
                  : "-"}
              </td>
              <td className="text-center">
                {element?.decommission_date
                  ? moment(element?.decommission_date).format("DD.MM.YYYY")
                  : "-"}
              </td>
              <td className="text-center">{element.maintenance_interval} T.</td>
              <td>{element.note}</td>
              <td>
                <div className="d-flex justify-content-end gap-3">
                  <Link className="text-success" to="#">
                    <i
                      className="mdi mdi-pencil font-size-18"
                      onClick={() => {
                        setSelectedEntry(element)
                        setEntryModal(true)
                      }}
                    ></i>
                  </Link>
                  <Link className="text-info" to="#">
                    <i
                      className="mdi mdi-cog font-size-18"
                      onClick={() => {
                        setSelectedEntry(element)
                        setPauseModal(true)
                      }}
                    ></i>
                  </Link>
                  <Link className="text-danger" to="#">
                    <i
                      className="mdi mdi-delete font-size-18"
                      onClick={() => {
                        setSelectedEntry(element)
                        setDeleteModal(true)
                      }}
                    ></i>
                  </Link>
                </div>
              </td>
            </tr>
          ))}

          {entry?.bookings?.length == 0 && (
            <tr>
              <td colSpan={10}>
                <h6 className="text-center mt-3">Keine Anlagen vorhanden</h6>
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      <BookingModal
        modal={entryModal}
        onSuccess={_ => {
          setEntryModal(false)
          loadData()
        }}
        onClose={_ => setEntryModal(false)}
        selectedEntry={selectedEntry}
        location={entry}
      />

      <BookingPauseModal
        modal={pauseModal}
        onSuccess={_ => {
          setPauseModal(false)
          loadData()
        }}
        onClose={_ => setPauseModal(false)}
        booking={selectedEntry}
      />

      <UserConfirmationModal
        message="Die Buchung und alle zugehörigen Termine werden gelöscht. Wollen Sie fortfahren?"
        onCancel={_ => setDeleteModal(false)}
        show={deleteModdal}
        onConfirm={handleDelete}
      />
    </div>
  )
}

export default Bookings
