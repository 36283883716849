import React from "react"
import { Modal, ModalBody } from "reactstrap"

const UserConfirmationModal = ({ show, onCancel, onConfirm, message }) => {
  return (
    <Modal size="sm" isOpen={show} toggle={onCancel} centered={true}>
      <div className="modal-content">
        <ModalBody className="px-4 py-5 pb-3 text-center">
          <button
            type="button"
            onClick={onCancel}
            className="btn-close position-absolute end-0 top-0 m-3"
          ></button>

          <p className="text-muted mb-4">{message}</p>

          <div className="hstack gap-2 justify-content-center mb-0">
            <button
              type="button"
              className="btn btn-secondary w-100"
              onClick={onCancel}
            >
              Abbrechen
            </button>
            <button
              type="button"
              className="btn btn-primary w-100"
              onClick={_ => {
                onConfirm()
              }}
            >
              Ja
            </button>
          </div>
        </ModalBody>
      </div>
    </Modal>
  )
}

export default UserConfirmationModal
