import React, { useEffect, useState } from "react"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator"
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import "flatpickr/dist/themes/material_blue.css"
const { SearchBar } = Search
import { Link } from "react-router-dom"
import { Col, Row, Card, CardBody, Table } from "reactstrap"
import Select from "react-select"
import { GetData } from "helpers/backend_helper"
import { useLoading } from "helpers/custom_hooks"
import { columns } from "./columns"

function Locations() {
  const setLoading = useLoading()
  const [data, setData] = useState([])
  const [filteredData, setfilteredData] = useState([])
  const [areas, setAreas] = useState([])
  const status = [
    "Alle",
    "offen",
    "aktiv",
    "inaktiv",
    "abgeschlossen",
    "ohne Standort",
  ]

  const [filterObj, setFilterObj] = useState({
    area_id: localStorage.getItem("location-area_id")
      ? parseInt(localStorage.getItem("location-area_id"), 10)
      : -1,
    status: localStorage.getItem("location-status") || "Alle",
  })
  const pageOptions = {
    sizePerPage: 20,
    totalSize: data.length,
    custom: true,
  }

  const loadData = async () => {
    setLoading(true)
    const data = await GetData("/locations")
    setData(data)

    const areas = await GetData("/areas")
    setAreas([
      { label: "Alle", value: -1 },
      ...areas?.map(a => ({ label: a.name, value: a.id })),
    ])

    setLoading(false)
  }

  useEffect(() => {
    loadData()
  }, [])

  const filterData = () => {
    /** User Filter */
    let result = data

    /* Area Filter */
    if (filterObj.area_id > 0)
      result = result.filter(d => d.area_id == filterObj.area_id)

    if (filterObj.status && filterObj.status != "Alle")
      result = result.filter(d => d.status == filterObj.status)

    result = result.sort((a, b) => {
      if (a.important && !b.important) return -1
      if (!a.important && b.important) return 1
      return b.completed - a.completed
    })

    setfilteredData(result)
  }

  useEffect(filterData, [filterObj, data])

  useEffect(() => {
    if (filterObj?.area_id)
      localStorage.setItem("location-area_id", filterObj.area_id)
    if (filterObj?.status)
      localStorage.setItem("location-status", filterObj.status)
  }, [filterObj])

  const rowClasses = row => {
    let classes = "bg-soft"

    if (row.status == "abgeschlossen") classes += " bg-danger"
    if (row.status == "inaktiv") classes += " bg-dark"
    if (row.status == "aktiv") classes += " bg-success"

    return classes
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions || [])}
                    keyField="id"
                    data={filteredData || []}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        data={filteredData}
                        columns={columns || []}
                        remote={{ filter: true }}
                        bootstrap4
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row>
                              <Col>
                                <h5 className="mb-0 card-title flex-grow-1">
                                  Standorte
                                </h5>
                              </Col>

                              <Col>
                                <div className="d-flex justify-content-end align-items-center">
                                  <div className="d-flex justify-content-end align-items-center">
                                    <SearchBar
                                      placeholder="Suche..."
                                      style={{ width: "200px" }}
                                      {...toolkitProps.searchProps}
                                    />

                                    <div
                                      style={{ width: "200px" }}
                                      className="mb-2 mx-1 ms-2"
                                    >
                                      <Select
                                        className="select2-selection w-100"
                                        value={{
                                          value: filterObj?.area_id,
                                          label: areas?.find(
                                            a => a.value == filterObj?.area_id
                                          )?.label,
                                        }}
                                        onChange={selectedOption => {
                                          setFilterObj({
                                            ...filterObj,
                                            area_id: selectedOption.value,
                                          })
                                        }}
                                        options={areas}
                                      />
                                    </div>
                                    <div
                                      style={{ width: "200px" }}
                                      className="mb-2 mx-1 ms-2"
                                    >
                                      <Select
                                        className="select2-selection w-100"
                                        value={{
                                          value: filterObj?.status,
                                          label: filterObj?.status,
                                        }}
                                        onChange={selectedOption => {
                                          setFilterObj({
                                            ...filterObj,
                                            status: selectedOption.value,
                                          })
                                        }}
                                        options={status.map(status => {
                                          return {
                                            label: status,
                                            value: status,
                                          }
                                        })}
                                      />
                                    </div>
                                  </div>
                                  <div className="flex-shrink-0 mb-2 ms-1">
                                    <Link
                                      to="/neuer-standort"
                                      className="btn btn-primary me-1"
                                    >
                                      Neuer Standort
                                    </Link>
                                  </div>
                                </div>
                              </Col>
                            </Row>

                            <Row>
                              <Col></Col>
                            </Row>

                            <div className="table-responsive">
                              <BootstrapTable
                                {...toolkitProps.baseProps}
                                {...paginationTableProps}
                                bordered={false}
                                rowClasses={rowClasses}
                                classes={
                                  "table align-middle table-nowrap table-check"
                                }
                                headerWrapperClasses={"table-light"}
                              />
                            </div>
                            <div className="pagination pagination-rounded justify-content-end mb-2">
                              <PaginationListStandalone {...paginationProps} />
                            </div>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Locations
