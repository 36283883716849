import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { isEmpty } from "lodash"
import deLocale from "@fullcalendar/core/locales/de"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import FullCalendar from "@fullcalendar/react"
import dayGridPlugin from "@fullcalendar/daygrid"
import interactionPlugin, { Draggable } from "@fullcalendar/interaction"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
//css
import "@fullcalendar/bootstrap/main.css"
import { Link } from "react-router-dom"

const Veranstaltungen = props => {
  const [event, setEvent] = useState({})

  // events validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      title: (event && event.title) || "",
      category: (event && event.category) || "bg-danger",
      street: (event && event.street) || "",
      postalCode: (event && event.postalCode) || "",
      city: (event && event.city) || "",
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Please Enter Your Event Name"),
      category: Yup.string().required("Please Select Your Category"),
      street: Yup.string().required("Please Enter Your Event Name"),
      postalCode: Yup.string().required("Please Enter Your Event Name"),
      city: Yup.string().required("Please Enter Your Event Name"),
    }),
    onSubmit: values => {
      if (isEdit) {
        const updateEvent = {
          id: event.id,
          title: values.title,
          classNames: values.category + " text-white",
          start: event.start,
        }
        // update event
        validation.resetForm()
      } else {
        const newEvent = {
          id: Math.floor(Math.random() * 100),
          title: values["title"],
          start: selectedDay ? selectedDay.date : new Date(),
          className: values.category + " text-white",
        }
        // save new event
        validation.resetForm()
      }
      setSelectedDay(null)
      toggle()
    },
  })

  // category validation
  const categoryValidation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      title: (event && event.title) || "",
      fahrer: (event && event.fahrer) || "",
      lieferdatum: (event && event.lieferdatum) || "",
      abholdatum: (event && event.abholdatum) || "",
      interval: (event && event.interval) || "",
      street: (event && event.street) || "",
      city: (event && event.city) || "",
      postalCode: (event && event.postalCode) || "",
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Please Enter Your Event Name"),
    }),
    onSubmit: values => {
      toggleCategory()
    },
  })
  const [modal, setModal] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [modalcategory, setModalcategory] = useState(false)

  const [selectedDay, setSelectedDay] = useState(0)
  const [isEdit, setIsEdit] = useState(false)

  const events = [
    {
      id: 1,
      title: "BASISLINE | Wernberg",
      street: "Bundesstraße 1",
      postalCode: "9241",
      city: "Wernberg",
      start: new Date().setDate(new Date().getDate()),
      className: "bg-warning text-white",
    },
    {
      id: 2,
      title: "EVENTLINE | Villach",
      street: "Bundesstraße 1",
      postalCode: "9241",
      city: "Wernberg",
      start: new Date().setDate(new Date().getDate() + 1),
      className: "bg-warning text-white",
    },
  ]

  useEffect(() => {
    if (!modal && !isEmpty(event) && !!isEdit) {
      setTimeout(() => {
        setEvent({})
        setIsEdit(false)
      }, 500)
    }
  }, [modal, event])

  /**
   * Handling the modal state
   */
  const toggle = () => {
    if (modal) {
      setModal(false)
      setEvent(null)
    } else {
      setModal(true)
    }
  }

  const toggleCategory = () => {
    setModalcategory(!modalcategory)
  }

  /**
   * Handling date click on calendar
   */
  const handleDateClick = arg => {
    const date = arg["date"]
    const day = date.getDate()
    const month = date.getMonth()
    const year = date.getFullYear()

    const currectDate = new Date()
    const currentHour = currectDate.getHours()
    const currentMin = currectDate.getMinutes()
    const currentSec = currectDate.getSeconds()
    const modifiedDate = new Date(
      year,
      month,
      day,
      currentHour,
      currentMin,
      currentSec
    )
    const modifiedData = { ...arg, date: modifiedDate }

    setSelectedDay(modifiedData)
    toggle()
  }

  /**
   * Handling click on event on calendar
   */
  const handleEventClick = arg => {
    const event = arg.event
    setEvent({
      id: event.id,
      title: event.title,
      street: event._def.extendedProps.street,
      start: event.start,
      postalCode: event._def.extendedProps.postalCode,
      city: event._def.extendedProps.city,
      category: event.classNames[0],
      event_category: event.classNames[0],
    })
    setIsEdit(true)
    toggle()
  }

  /**
   * On delete event
   */
  const handleDeleteEvent = () => {
    if (event && event.id) {
    }
    setDeleteModal(false)
    toggle()
  }
  /**
   * On calendar drop event
   */
  const onDrop = event => {
    const date = event["date"]
    const day = date.getDate()
    const month = date.getMonth()
    const year = date.getFullYear()

    const currectDate = new Date()
    const currentHour = currectDate.getHours()
    const currentMin = currectDate.getMinutes()
    const currentSec = currectDate.getSeconds()
    const modifiedDate = new Date(
      year,
      month,
      day,
      currentHour,
      currentMin,
      currentSec
    )

    const draggedEl = event.draggedEl
    const draggedElclass = draggedEl.className
    if (
      draggedEl.classList.contains("external-event") &&
      draggedElclass.indexOf("fc-event-draggable") == -1
    ) {
      const modifiedData = {
        id: Math.floor(Math.random() * 100),
        title: draggedEl.innerText,
        start: modifiedDate,
        className: draggedEl.className,
      }
    }
  }

  const renderEventContent = eventInfo => {
    return (
      <Col>
        {eventInfo.event.title.split(";").map((title, index) => (
          <Row key={index} className="m-0 text-start">
            <span
              className={index === 0 ? "font-weight-bold font-size-16" : ""}
            >
              {title}
            </span>
          </Row>
        ))}
      </Col>
    )
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Kalendar" breadcrumbItem="Veranstaltungen" />
          <Row>
            <Col className="col-12">
              <Row>
                <Col lg={12}>
                  {/* fullcalendar control */}
                  <FullCalendar
                    plugins={[dayGridPlugin, interactionPlugin]}
                    slotDuration={"24:00:00"}
                    handleWindowResize={true}
                    themeSystem="bootstrap"
                    initialView="dayGridWeek"
                    customButtons={{
                      myCustomButton: {
                        text: "Neue Veranstaltung",
                        click: function () {
                          setIsEdit(false)
                          toggle()
                        },
                      },
                    }}
                    headerToolbar={{
                      left: "prev,next today,myCustomButton",
                      center: "title",
                    }}
                    events={events}
                    displayEventTime={false}
                    editable={true}
                    droppable={true}
                    selectable={true}
                    weekends={true}
                    dateClick={handleDateClick}
                    eventClick={handleEventClick}
                    drop={onDrop}
                    eventContent={renderEventContent}
                    locale={deLocale}
                    weekNumbers={true}
                  />

                  {/* New/Edit event modal */}
                  <Modal isOpen={modal} className={props.className} centered>
                    <ModalHeader
                      toggle={toggle}
                      tag="h5"
                      className="py-3 px-4 border-bottom-0"
                    >
                      {!!isEdit
                        ? "Veranstaltung bearbeiten"
                        : "Neuer Veranstaltung"}
                    </ModalHeader>
                    <ModalBody className="p-4">
                      <Form
                        onSubmit={e => {
                          e.preventDefault()
                          validation.handleSubmit()
                          return false
                        }}
                      >
                        <Row>
                          <Col className="col-12">
                            <div className="mb-3">
                              <Label className="form-label">
                                Veranstaltung
                              </Label>
                              <Input
                                name="title"
                                type="text"
                                // value={event ? event.title : ""}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.title || ""}
                                invalid={
                                  validation.touched.title &&
                                  validation.errors.title
                                    ? true
                                    : false
                                }
                                disabled={isEdit}
                              />
                              {validation.touched.title &&
                              validation.errors.title ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.title}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col className="col-12">
                            <div className="mb-3">
                              <Label className="form-label">Straße</Label>
                              <Input
                                name="street"
                                type="text"
                                // value={event ? event.title : ""}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.street || ""}
                                invalid={
                                  validation.touched.street &&
                                  validation.errors.street
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.street &&
                              validation.errors.street ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.street}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col className="col-12">
                            <div className="mb-3">
                              <Label className="form-label">Postleitzahl</Label>
                              <Input
                                name="postalCode"
                                type="text"
                                // value={event ? event.postalCode : ""}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.postalCode || ""}
                                invalid={
                                  validation.touched.postalCode &&
                                  validation.errors.postalCode
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.postalCode &&
                              validation.errors.postalCode ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.postalCode}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col className="col-12">
                            <div className="mb-3">
                              <Label className="form-label">Stadt</Label>
                              <Input
                                name="city"
                                type="text"
                                // value={event ? event.city : ""}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.city || ""}
                                invalid={
                                  validation.touched.city &&
                                  validation.errors.city
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.city &&
                              validation.errors.city ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.city}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col className="col-12">
                            <div className="mb-3">
                              <Label className="form-label">Lieferdatum</Label>
                              <Input
                                type="date"
                                name="lieferdatum"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.lieferdatum || ""}
                                invalid={
                                  validation.touched.lieferdatum &&
                                  validation.errors.lieferdatum
                                    ? true
                                    : false
                                }
                              />
                            </div>
                          </Col>
                          <Col className="col-12">
                            <div className="mb-3">
                              <Label className="form-label">Abholdatum</Label>
                              <Input
                                type="date"
                                name="abholdatum"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.abholdatum || ""}
                                invalid={
                                  validation.touched.abholdatum &&
                                  validation.errors.abholdatum
                                    ? true
                                    : false
                                }
                              />
                            </div>
                          </Col>
                          <Col className="col-12">
                            <div className="mb-3">
                              <Label className="form-label">
                                Service-Intervall
                              </Label>
                              <Input
                                type="select"
                                name="interval"
                                // value={event ? event.interval : "bg-primary"}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.interval || ""}
                                invalid={
                                  validation.touched.interval &&
                                  validation.errors.interval
                                    ? true
                                    : false
                                }
                              >
                                <option value="bg-danger">Kein Service</option>
                                <option value="bg-success">Täglich</option>
                                <option value="bg-success">Alle 2 Tage</option>
                                <option value="bg-success">
                                  2 x pro Woche
                                </option>
                                <option value="bg-primary">7-tägig</option>
                                <option value="bg-primary">14-tägig</option>
                              </Input>
                              {validation.touched.interval &&
                              validation.errors.interval ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.interval}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col className="col-12">
                            <div className="mb-3">
                              <Label className="form-label">Fahrer</Label>
                              <Input
                                type="select"
                                name="fahrer"
                                // value={event ? event.fahrer : "bg-primary"}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.fahrer || ""}
                                invalid={
                                  validation.touched.fahrer &&
                                  validation.errors.fahrer
                                    ? true
                                    : false
                                }
                              >
                                <option value="bg-danger">Fahrer 1</option>
                                <option value="bg-success">Fahrer 2</option>
                                <option value="bg-primary">Fahrer 3</option>
                                <option value="bg-info">Fahrer 4</option>
                                <option value="bg-dark">Fahrer 5</option>
                              </Input>
                              {validation.touched.fahrer &&
                              validation.errors.fahrer ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.fahrer}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        </Row>

                        <Row className="mt-2">
                          <Col className="col-4">
                            {!!isEdit && (
                              <button
                                type="button"
                                className="btn btn-danger me-2"
                                onClick={() => setDeleteModal(true)}
                              >
                                Löschen
                              </button>
                            )}
                          </Col>
                          <Col className="col-8 text-end">
                            {isEdit && (
                              <Link
                                to="/termine"
                                className="btn btn-light me-2"
                                onClick={toggle}
                              >
                                Termine anzeigen
                              </Link>
                            )}
                            <button
                              type="submit"
                              className="btn btn-success"
                              id="btn-save-event"
                            >
                              Speichern
                            </button>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Veranstaltungen
