import axios from "axios"

const API_URL = process.env.REACT_APP_API_BASE_URL

const axiosApi = axios.create({
  baseURL: API_URL,
})

axiosApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
)

export async function get(url, config = {}) {
  try {
    return await axiosApi
      .get(url, { ...config, headers: authHeader() })
      .then(response => response.data)
  } catch (error) {
    handleError(error)
  }
}

export async function post(url, data, config = {}) {
  try {
    return axiosApi
      .post(url, { ...data }, { ...config, headers: authHeader() })
      .then(response => response.data)
  } catch (error) {
    handleError(error)
  }
}

export async function put(url, data, config = {}) {
  try {
    return axiosApi
      .put(url, data, { ...config, headers: authHeader() })
      .then(response => response.data)
  } catch (error) {
    handleError(error)
  }
}

export async function del(url, config = {}) {
  try {
    return await axiosApi
      .delete(url, { ...config, headers: authHeader() })
      .then(response => response.data)
  } catch (error) {
    handleError(error)
  }
}

function authHeader() {
  const authUser = localStorage.getItem("authUser")
  if (authUser) {
    return { Authorization: authUser }
  } else {
    return {}
  }
}

const handleError = error => {
  console.log(error)
  if (error?.response?.status == 401) return (window.location.href = "/logout")

  alert("Es ist ein unerwarteter Fehler aufgetreten!")
  throw error
}
