import { del, get, post, put } from "./api_helper"

/* General */

export const GetData = route => get(route)

export const CreateEntry = (route, reqObj) => post(route, reqObj)

export const DeleteEntry = route => del(route)

export const UpdateEntry = (route, reqObj) => put(route, reqObj)

/* User */

export const GetUsersByName = name => get("/users/searchByName/" + name)

export const LoginUser = data => post("/users/login", data)

export const CreateUser = data => post("/users", data)

/* Customers */

export const GetCustomersByName = name => get("/customers/searchByName/" + name)

/* Facilities */

export const GetFacilitiesByName = name =>
  get("/facilities/searchByName/" + name)
