import moment from "moment"
import React from "react"
import { Link } from "react-router-dom"
import { Card, CardBody, Col, Table } from "reactstrap"

const DetailsSection = ({ entry }) => {
  const getIconForLocation = location => {
    const delivery_date = moment(location.delivery_date_date)
    const pickup_date = moment(location.pickup_date)
    const now = moment()

    if (!pickup_date) return
    if (now.isBetween(delivery_date, pickup_date))
      return "bg-warning text-warning"

    if (now.isAfter(pickup_date)) return "bg-success text-success"
  }

  return (
    <React.Fragment>
      <Col xl={8}>
        <Card>
          <CardBody>
            <h4 className="card-title mb-4">Standorte</h4>
            <div className="table-responsive">
              <Table className="table table-nowrap align-middle mb-0">
                <thead>
                  <tr>
                    <th scope="col">Standort</th>
                    <th scope="col">Adresse</th>
                    <th scope="col">Zustelldatum</th>
                    <th scope="col">Abholdatum</th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {entry?.locations.map((location, key) => (
                    <tr key={key}>
                      <th scope="row">
                        <div className="d-flex align-items-center">
                          <div className="avatar-xs me-3">
                            <span
                              className={
                                "avatar-title rounded-circle bg-soft font-size-18 " +
                                getIconForLocation(location)
                              }
                            >
                              <i className="bx bx-time-five" />
                            </span>
                          </div>
                          <Link to={"/standorte/" + location.id}>
                            {location.name}
                          </Link>
                        </div>
                      </th>
                      <td>
                        <div className="d-flex align-items-center">
                          <span>
                            {location.address}
                            <br />
                            {location.postal_code} {location.city}
                          </span>
                        </div>
                      </td>
                      <td>
                        <div className="text-muted">
                          {location?.delivery_date
                            ? moment(location.delivery_date).format(
                                "DD.MM.YYYY"
                              )
                            : "-"}
                        </div>
                      </td>
                      <td>
                        <div className="text-muted">
                          {location.pickup_date
                            ? moment(location.pickup_date).format("DD.MM.YYYY")
                            : "-"}
                        </div>
                      </td>
                      <td>
                        <div className="text-muted">
                          {location.nachsterTermin}
                        </div>
                      </td>
                    </tr>
                  ))}
                  {entry?.locations?.length == 0 && (
                    <tr>
                      <td colSpan={10}>
                        <h6 className="text-center mt-3">
                          Keine Standorte vorhanden
                        </h6>
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  )
}

export default DetailsSection
